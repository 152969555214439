import {
  Head,
  router
} from '@inertiajs/react';
import { createStyles, Container, Title, Text, Button, Group, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: rem(0),
    paddingBottom: rem(80),
  },

  inner: {
    position: 'relative',
  },

  bg: {
    position: "absolute",
    width: '100%',
    fontWeight: 1000,
    fontSize: rem(400),
    textAlign: 'center',
    opacity: 0.75,
  },

  content: {
    paddingTop: rem(220),
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: rem(120),
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: rem(38),

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(32),
    },
  },

  description: {
    maxWidth: rem(540),
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

export default function ErrorPage({ status, message }:{status: number, message: string}) {
  const title = {
    503: 'A szolgáltatás nem elérhető',
    500: 'Szerver hiba',
    404: 'Az oldal nem található',
    403: 'Elutasítva'
  }[status]

  const description = {
    503: 'Elnézést kérünk, jelenleg karbantartást végzünk. Kérjük, később próbálkozzon újra.',
    500: 'Elnézést, egy váratlan hiba történt a szervereinken.',
    404: 'Elnézést, a keresett oldal nem található.',
    403: 'Elnézést, Ön nem jogosult az oldal megtekintésére.'
  }[status]



  const { classes } = useStyles();

  return (
    <Container className={"relative h-screen"}>
      <Head title={title} />
      <div className={'flex flex-col justify-center items-center h-screen'}>
        <div className={'font-black text-9xl opacity-25 h-min leading-none mb-10'}>{status}</div>
        <div className={"flex flex-col"}>
          <Title className={classes.title}>{title}</Title>
          <Text color="dimmed" size="lg" align="center" className={classes.description}>
            {description}
          </Text>
          <Group position="center">
            <Button onClick={()=>router.visit('/')} size="md">Vissza a kezdőképernyőre</Button>
          </Group>
        </div>
      </div>
    </Container>
  )
}
